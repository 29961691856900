import React, { useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { latestSliders, tailer } from "../AppUtilities";
import { brand } from "../AppUtilities";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { videos } from "../AppUtilities";
import { bannerMovies } from "../AppUtilities";
import { IMG, NOIMG } from "../API/constant";
import { Link } from "react-router-dom";
import * as API from "../API/Index";
import MovieDetailsModal from "../pages/Movie/MovieDetailsModal";
const ReleaseSlide = ({ allData, movieWishListAdd }) => {
  const [open, setOpen] = useState(false);
  const [movieSlug, setMovieSlug] = useState("");
  const [videoFormat, setVideoFormat] = useState("");
  const [individualMoviles, setIndividualMoviles] = useState("");

  const onCloseModal = () => {
    setOpen(false);
  };

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 4,
    },
    tabletlandscape: {
      breakpoint: { max: 767, min: 667 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 480, min: 0 },
      items: 2,
    },
  };

  const hoverHandeler = async (data, movieId) => {
    const header = localStorage.getItem("_tokenCode");
    if (data || open) {
      try {
        const reqOBj = {
          id: movieId,
          type: "trailer",
        };

        const response = await API.getMovieDetailsS3(reqOBj, header);
        setVideoFormat(response.data.fileUrl);
      } catch (error) {}
    }
  };

  const individualMovie = async (data) => {
    setOpen(true);
    const header = localStorage.getItem("_tokenCode");
    try {
      const response = await API.getMovieDetils(data);
      console.log("response", response);
      setIndividualMoviles(response.data.data);
      const reqObj = {
        type: "movie",
        id: response.data.data.id,
      };
      // console.log("reqObj", reqObj);
      const sresponse = await API.getMovieDetailsS3(reqObj, header);
      setVideoFormat(sresponse.data.fileUrl);
      // console.log("response", response);
    } catch (error) {}
  };
  return (
    <>
      <Carousel
        responsive={responsive}
        swipeable={true}
        draggable={true}
        showDots={false} // Hide dots
        arrows={false} // Hide arrows
        ssr={true} // means to render carousel on server-side.
        infinite={true}
        autoPlay={true}
        autoPlaySpeed={3000}
        customTransition="all 1s linear"
      >
        {/* item */}

        {allData.map((item, index) => (
          <div key={index}>
            <div
              class="item"
              onMouseEnter={() => hoverHandeler(true, item.id)}
              onMouseLeave={() => hoverHandeler(false)}
            >
              <div className="defaultPoster">
                <img
                  src={item.poster === null ? NOIMG : IMG + item.poster}
                  alt=""
                  class="w-100"
                />
                <div class="movie_content">
                  <div class="btngroup w-100">
                    <ul>
                      <li class="p-h-5">
                        <Link
                          state={{ dataMain: allData }}
                          to={`/movie-details/${item.slug}`}
                        >
                          <span class="btn banner_btn btn-light d-inline-block">
                            <i class="fa-solid fa-play"></i>
                          </span>
                        </Link>
                      </li>

                      <li class="p-h-5">
                        <span
                          onClick={() => movieWishListAdd(item.id)}
                          class="btn banner_btn btn-outline-light d-inline-block"
                        >
                          <i class="bi bi-heart-fill"></i>
                        </span>
                      </li>
                      <li class="p-h-5">
                        <span
                          class="btn banner_btn btn-outline-light d-inline-block"
                          onClick={() => individualMovie(item.slug)}
                        >
                          <i class="fa-solid fa-chevron-down"></i>
                        </span>
                      </li>
                    </ul>
                  </div>
                  <ul class="d-flex btmText">
                    <li>{item.release_date}</li>
                    <li>{item.duration}</li>
                  </ul>
                </div>
              </div>

              <div className="onHoverPoster">
                <video
                  src={videoFormat}
                  autoPlay
                  muted // autoplay usually requires muted to work in many browsers
                  loop // optional, to loop the video
                ></video>
                <div class="movie_content">
                  <div class="btngroup w-100">
                    <ul>
                      <li class="p-h-5">
                        <Link
                          state={{ dataMain: allData }}
                          to={`/movie-details/${item.slug}`}
                        >
                          <span class="btn banner_btn btn-light d-inline-block">
                            <i class="fa-solid fa-play"></i>
                          </span>
                        </Link>
                      </li>

                      <li class="p-h-5">
                        <span class="btn banner_btn btn-outline-light d-inline-block">
                          <i class="bi bi-heart-fill"></i>
                        </span>
                      </li>
                      <li class="p-h-5">
                        <span
                          class="btn banner_btn btn-outline-light d-inline-block"
                          onClick={() => individualMovie(item.slug)}
                        >
                          <i class="fa-solid fa-chevron-down"></i>
                        </span>
                      </li>
                    </ul>
                    <ul class="d-flex btmText">
                      <li>{item.release_date}</li>
                      <li>{item.duration}</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Carousel>

      {/* modal */}
      <MovieDetailsModal
        individualMoviles={individualMoviles}
        open={open}
        onCloseModal={onCloseModal}
        videoFormat={videoFormat}
      />
    </>
  );
};

export default ReleaseSlide;
