import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { tailer } from "../AppUtilities";
import { IMG } from "../API/constant";
import { Link } from "react-router-dom";

const MovieSlider = ({ topWeekMovie }) => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 6,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 6,
    },
    tablet: {
      breakpoint: { max: 1024, min: 600 },
      items: 4,
    },
    mobile: {
      breakpoint: { max: 600, min: 414 },
      items: 2,
    },
    smallmobile: {
      breakpoint: { max: 414, min: 0 },
      items: 2,
    },
  };
  return (
    <>
      <section class="top_week movieWeek pt-0 pb-5 latest_release noBg">
        <div class="container-fluid">
          <div class="justify-content-center row">
            <div class="col-11 d-flex align-items-center justify-content-between">
              <h3>Top Movies of the week</h3>
              {/* <span>
                <a href="#" class="text-light">
                  View all
                </a>
              </span> */}
            </div>
            <div class="col-11">
              <hr class="mt-4 mb-4" />

              <Carousel
                responsive={responsive}
                swipeable={true}
                draggable={true}
                showDots={true}
                ssr={true} // means to render carousel on server-side.
                infinite={true}
                autoPlay={true}
                autoPlaySpeed={3000}
                customTransition="all 1s linear"
              >
                {topWeekMovie.map((item, index) => (
                  <div>
                    <div class="item floatContent">
                      <div className="defaultPoster">
                        <Link to={`/movie-details/${item.slug}`}>
                          <img
                            src={IMG + item.poster}
                            alt=""
                            class="w-50 main_img"
                          />
                          <div class="week_content">
                              <h3>
                                {index === 10 ? index + 1 : `${index + 1}`}
                              </h3>
                          </div>
                        </Link>
                      </div>

                      <div className="onHoverPoster">
                        <video
                          src={tailer}
                          autoPlay
                          muted // autoplay usually requires muted to work in many browsers
                          loop // optional, to loop the video
                        ></video>
                        <div class="movie_content">
                          <div class="btngroup w-100">
                            <ul>
                              <li class="p-h-5">
                                <Link
                                  to='/'
                                >
                                  <span class="btn banner_btn btn-light d-inline-block">
                                    <i class="fa-solid fa-play"></i>
                                  </span>
                                </Link>
                              </li>

                              <li class="p-h-5">
                                <span class="btn banner_btn btn-outline-light d-inline-block">
                                  <i class="bi bi-heart-fill"></i>
                                </span>
                              </li>
                              <li class="p-h-5">
                                <span
                                  class="btn banner_btn btn-outline-light d-inline-block"
                                >
                                  <i class="fa-solid fa-chevron-down"></i>
                                </span>
                              </li>
                            </ul>
                            <ul class="d-flex btmText">
                              <li>2024</li>
                              <li>17min 56sec</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </Carousel>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default MovieSlider;
