import React, { useState } from "react";
import { logos, users } from "../AppUtilities";
import { Link, NavLink } from "react-router-dom";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import * as Accordion from "@radix-ui/react-accordion";
import { ChevronDownIcon } from "@radix-ui/react-icons";
const Header = ({ setIsuser, isLogin }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };
  return (
    <>
      <header>
        <div class="container-fluid">
          <div class="row justify-content-center">
            <div class="col-12 col-sm-11 d-flex justify-content-between align-items-center">
              <span class="logo">
                <Link to="/home">
                  <img src={logos} alt="" class="img-fluid" />
                </Link>
              </span>
              <div className="d-flex align-items-center">
                <nav>
                  <div class="hamburger" id="hamburger-1" onClick={toggleDrawer}>
                    <span class="line"></span>
                    <span class="line"></span>
                    <span class="line"></span>
                  </div>
                  <ul>
                    <li>
                      <NavLink to="/home">Home</NavLink>
                    </li>
                    <li>
                      <NavLink to="/movie-list">
                        {" "}
                        Explore <i class="fa-solid fa-chevron-down"></i>
                      </NavLink>
                      <div class="megamenu">
                        <ul class="row">
                          <li class="col-sm-4 col-md-3">
                            <small></small>
                            <ul>
                              <li>
                                <NavLink to="#">
                                  <h6>By Country</h6>
                                </NavLink>
                              </li>
                              <li>
                                <NavLink to="#">
                                  <h6>By Director</h6>
                                </NavLink>
                              </li>
                              <li>
                                <NavLink to="#">
                                  <h6>By Studio</h6>
                                </NavLink>
                              </li>
                              <li>
                                <NavLink to="#">
                                  <h6>By Year</h6>
                                </NavLink>
                              </li>
                              <li>
                                <NavLink to="#">
                                  <h6>New Releases</h6>
                                </NavLink>
                              </li>
                              <li>
                                <NavLink to="#">
                                  <h6>Trending</h6>
                                </NavLink>
                              </li>
                              <li>
                                <NavLink to="#">
                                  <h6>Recommended for You</h6>
                                </NavLink>
                              </li>
                            </ul>
                          </li>
                          <li class="col-sm-4 col-md-3">
                            <small>By Genre</small>
                            <ul>
                              <li>
                                <NavLink to="#">Action</NavLink>
                              </li>
                              <li>
                                <NavLink to="#">Drama</NavLink>
                              </li>
                              <li>
                                <NavLink to="#">Comedy</NavLink>
                              </li>
                              <li>
                                <NavLink to="#">Documentary</NavLink>
                              </li>
                              <li>
                                <NavLink to="#">Horror</NavLink>
                              </li>
                              <li>
                                <NavLink to="#">Sci-Fi</NavLink>
                              </li>
                              <li>
                                <NavLink to="#">Romance</NavLink>
                              </li>
                              <li>
                                <NavLink to="#">Thriller</NavLink>
                              </li>
                              <li>
                                <NavLink to="#">Animation</NavLink>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </li>
                    <li>
                      <NavLink to="javascript:void(0)">
                        {" "}
                        Live <i class="fa-solid fa-chevron-down"></i>
                      </NavLink>
                      <div class="megamenu">
                        <ul class="row">
                          <li class="col-sm-4 col-md-3">
                            <ul>
                              <li>
                                <NavLink to="#">
                                  <h6>Upcoming Events</h6>
                                </NavLink>
                              </li>
                              <li>
                                <NavLink to="#">
                                  <h6>Live Now</h6>
                                </NavLink>
                              </li>
                              <li>
                                <NavLink to="#">
                                  <h6>Past Streams</h6>
                                </NavLink>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </li>

                    <li>
                      <NavLink to="javascript:void(0)">
                        {" "}
                        Festivals <i class="fa-solid fa-chevron-down"></i>
                      </NavLink>
                      <div class="megamenu">
                        <ul class="row">
                          <li class="col-sm-4 col-md-3">
                            <ul>
                              <li>
                                <NavLink to="#">
                                  <h6>Current Festivals</h6>
                                </NavLink>
                              </li>
                              <li>
                                <NavLink to="#">
                                  <h6>Awards & Winners</h6>
                                </NavLink>
                              </li>
                              <li>
                                <NavLink to="#">
                                  <h6>Past Festivals</h6>
                                </NavLink>
                              </li>
                              <li>
                                <NavLink to="#">
                                  <h6>Submit Your Film</h6>
                                </NavLink>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </li>
                    <li>
                      <NavLink to="javascript:void(0)">
                        {" "}
                        Filmmakers <i class="fa-solid fa-chevron-down"></i>
                      </NavLink>

                      <div class="megamenu">
                        <ul class="row">
                          <li class="col-sm-4 col-md-3">
                            <ul>
                              <li>
                                <NavLink to="#">
                                  <h6>Filmmaker Portal</h6>
                                </NavLink>
                              </li>
                              <li>
                                <NavLink to="#">
                                  <h6>Resources & Tools</h6>
                                </NavLink>
                              </li>
                              <li>
                                <NavLink to="#">
                                  <h6>Success Stories</h6>
                                </NavLink>
                              </li>
                              <li>
                                <NavLink to="#">
                                  <h6>Interviews</h6>
                                </NavLink>
                              </li>
                            </ul>
                          </li>
                          
                        </ul>
                      </div>
                    </li>
                    <li>
                      <NavLink to="/about">
                        {" "}
                        Community <i class="fa-solid fa-chevron-down"></i>
                      </NavLink>
                      <div class="megamenu">
                        <ul class="row">
                          <li class="col-sm-4 col-md-3">
                            <ul>
                              <li>
                                <NavLink to="#">
                                  <h6>Discussions</h6>
                                </NavLink>
                              </li>
                              <li>
                                <NavLink to="#">
                                  <h6>Watch Parties</h6>
                                </NavLink>
                              </li>
                              <li>
                                <NavLink to="#">
                                  <h6>User Reviews</h6>
                                </NavLink>
                              </li>
                              <li>
                                <NavLink to="#">
                                  <h6>Social Media</h6>
                                </NavLink>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </li>
                    <li>
                      <NavLink to="/about">
                        {" "}
                        About Us <i class="fa-solid fa-chevron-down"></i>
                      </NavLink>
                      <div class="megamenu">
                        <ul class="row">
                          <li class="col-sm-4 col-md-3">
                            <small>About Us</small>
                            <ul>
                              <li>
                                <a href="#mission">
                                  <h6>Our Purpose</h6>
                                </a>
                              </li>
                              <li>
                                <a href="#team">
                                  <h6>The Team</h6>
                                </a>
                              </li>
                              <li>
                                <a href="#career">
                                  <h6>Careers</h6>
                                </a>
                              </li>
                              <li>
                                <a href="#press">
                                  <h6>Press Releases</h6>
                                </a>
                              </li>
                              <li>
                                <a href="#contact">
                                  <h6>Contact Us</h6>
                                </a>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </li>
                    {/* <li>
                      <NavLink to="#">Plan <i class="fa-solid fa-chevron-down"></i></NavLink>

                      <div class="megamenu">
                        <ul class="row">
                          <li class="col-sm-4 col-md-3">
                              <small>Plan</small>
                              <ul>
                                <li><NavLink to="/plans"><h6>Subscription Plans</h6></NavLink></li>
                                <li><NavLink to="#"><h6>Pay-Per-View Options</h6></NavLink></li>
                                <li><NavLink to="#"><h6>Shop & Watch</h6></NavLink></li>
                                <li><NavLink to="#"><h6>Free Tickets</h6></NavLink></li>
                              </ul>
                          </li>
                        </ul>
                      </div>
                    </li> */}

                    {/* <li>
                      <NavLink to="/">Creators</NavLink>
                    </li> */}
                    {/* <li>
                      <NavLink to="/"> Shop <i class="fa-solid fa-chevron-down"></i></NavLink>
                      <div class="megamenu">
                        <ul class="row">
                          <li class="col-sm-4 col-md-3">
                              <small>Shop</small>
                              <ul>
                                <li><NavLink to="#"><h6>Merchandise</h6></NavLink></li>
                                <li><NavLink to="#"><h6>Posters & Art</h6></NavLink></li>
                                <li><NavLink to="#"><h6>DVDs & Blu-rays</h6></NavLink></li>
                                <li><NavLink to="#"><h6>Digital Downloads</h6></NavLink></li>
                                <li><NavLink to="#"><h6>Exclusive Content</h6></NavLink></li>
                              </ul>
                          </li>
                        </ul>
                      </div>
                    </li> */}

                    {/* <li>
                      <NavLink to="/Introduction">Series</NavLink>
                    </li>
                    <li>
                      <NavLink to="/dd">My List</NavLink>
                    </li> */}
                  </ul>
                </nav>
                <ul class="infos">
                  <li>
                    <NavLink to="#">

                      {/* <i class="bi bi-search"></i> */}
                    </NavLink>
                  </li>
                  <li>
                    <Link
                      to="/creator/sign-up"
                      className="btn btn-secondary2 becomebtn"
                    >
                      Become a creator
                    </Link>
                  </li>
                  <li className="useRs">
                    <Link to={isLogin ? "/set-profile" : "/login"}>
                      {/* <i class="bi bi-person-fill"></i> */}
                      <img src={users} className="userthumb" />
                    </Link>
                    <ul className="cusTomDropdown">
                      <li>
                        <NavLink to="#">
                          <i class="fa-regular fa-user"></i> Profile
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="#">
                          <i class="fa-solid fa-gear"></i> Settings
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="#">
                          <i class="fa-solid fa-eye"></i> Watchlist
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="#">
                          <i class="fa-solid fa-list-check"></i> Viewing History
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="#">
                          <i class="fa-regular fa-bell"></i> Subscription Details
                        </NavLink>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </header>
      <Drawer
        open={isOpen}
        onClose={toggleDrawer}
        direction="right"
        className="mobileNav"
      >
        <div>
          <div className="d-flex flex-column justify-content-end">
            <div className="d-flex justify-content-between align-items-center pl-5">
                <span>
                  <Link
                    to="/creator/sign-up"
                    className="btn btn-secondary2 becomebtn"
                  >
                    Become a creator
                  </Link>
                </span>

                <span onClick={() => setIsOpen(false)} className="closeBtn"><i class="fa-solid fa-circle-xmark"></i></span>
            </div>
            
            <Accordion.Root type="single" collapsible>
              <Accordion.Item value="item-1">
                <Accordion.Header>
                  <Accordion.Trigger>Home 
                  </Accordion.Trigger>
                </Accordion.Header>
              </Accordion.Item>

              <Accordion.Item value="item-2">
                <Accordion.Header>
                  <Accordion.Trigger>Explore 
                     <ChevronDownIcon className="AccordionChevron" aria-hidden />
                  </Accordion.Trigger>
                </Accordion.Header>
                <Accordion.Content>
                  <ul>
                    <li>
                      <a href="">By Country</a>
                    </li>
                    <li>
                      <a href="">By Director</a>
                    </li>
                    <li>
                      <a href="">By Studio</a>
                    </li>
                    <li>
                      <a href="">By Year</a>
                    </li>
                    <li>
                      <a href="">New Releases</a>
                    </li>
                    <li>
                      <a href="">Trending</a>
                    </li>
                    <li>
                      <a href="">Recommended for You</a>
                    </li>
                    <li>
                      <a href="">
                        Action
                      </a>
                    </li>
                    <li>
                      <a href="">
                        Drama
                      </a>
                    </li>
                    <li>
                      <a href="">
                        Comedy
                      </a>
                    </li>
                    <li>
                      <a href="">
                        Documentary
                      </a>
                    </li>
                    <li>
                      <a href="">
                        Horror
                      </a>
                    </li>
                    <li>
                      <a href="">
                        Sci-Fi
                      </a>
                    </li>
                    <li>
                      <a href="">
                        Romance
                      </a>
                    </li>
                    <li>
                      <a href="">
                        Thriller
                      </a>
                    </li>
                    <li>
                      <a href="">
                        Animation
                      </a>
                    </li>
                  </ul>
                </Accordion.Content>
              </Accordion.Item>
              <Accordion.Item value="item-3">
                <Accordion.Header>
                  <Accordion.Trigger>live 
                     <ChevronDownIcon className="AccordionChevron" aria-hidden />
                  </Accordion.Trigger>
                </Accordion.Header>
                <Accordion.Content>
                  <ul>
                    <li>
                      <a href="">Upcoming Events</a>
                    </li>
                    <li>
                      <a href="">Live Now</a>
                    </li>
                    <li>
                      <a href="">Past Streams</a>
                    </li>
                  </ul>
                </Accordion.Content>
              </Accordion.Item>
              <Accordion.Item value="item-4">
                <Accordion.Header>
                  <Accordion.Trigger>Festivals 
                     <ChevronDownIcon className="AccordionChevron" aria-hidden />
                  </Accordion.Trigger>
                </Accordion.Header>
                <Accordion.Content>
                  <ul>
                    <li>
                      <a href="">Current Festivals</a>
                    </li>
                    <li>
                      <a href="">Past Festivals</a>
                    </li>
                    <li>
                      <a href="">Submit Your Film</a>
                    </li>
                     <li>
                      <a href="">Awards & Winners</a>
                    </li>
                  </ul>
                </Accordion.Content>
              </Accordion.Item>
              <Accordion.Item value="item-5">
                <Accordion.Header>
                  <Accordion.Trigger>Filmmakers
                     <ChevronDownIcon className="AccordionChevron" aria-hidden />
                  </Accordion.Trigger>
                </Accordion.Header>
                <Accordion.Content>
                  <ul>
                    <li>
                      <a href="">Filmmaker Portal</a>
                    </li>
                    <li>
                      <a href="">Success Stories</a>
                    </li>
                    <li>
                      <a href="">Interviews</a>
                    </li>
                     <li>
                      <a href="">Resources & Tools</a>
                    </li>
                  </ul>
                </Accordion.Content>
              </Accordion.Item>
              <Accordion.Item value="item-6">
                <Accordion.Header>
                  <Accordion.Trigger>Community 
                     <ChevronDownIcon className="AccordionChevron" aria-hidden />
                  </Accordion.Trigger>
                </Accordion.Header>
                <Accordion.Content>
                  <ul>
                    <li>
                      <a href="">Discussions</a>
                    </li>
                    <li>
                      <a href="">User Reviews</a>
                    </li>
                    <li>
                      <a href="">Social Media</a>
                    </li>
                     <li>
                      <a href="">Watch Parties</a>
                    </li>
                  </ul>
                </Accordion.Content>
              </Accordion.Item>
              <Accordion.Item value="item-7">
                <Accordion.Header>
                  <Accordion.Trigger>About Us 
                     <ChevronDownIcon className="AccordionChevron" aria-hidden />
                  </Accordion.Trigger>
                </Accordion.Header>
                <Accordion.Content>
                  <ul>
                    <li>
                      <a href=""> Our Purpose</a>
                    </li>
                    <li>
                      <a href="">The Team</a>
                    </li>
                    <li>
                      <a href="">Careers</a>
                    </li>
                    <li>
                      <a href="">Press Releases</a>
                    </li>
                     <li>
                      <a href="">Contact Us</a>
                    </li>
                  </ul>
                </Accordion.Content>
              </Accordion.Item>
            </Accordion.Root>
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default Header;
