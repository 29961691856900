import React, { useEffect, useState } from 'react';
import * as API from "../../API/Index.js";
import { IMG } from '../../API/constant.js';
import { useParams } from 'react-router';
import moment from "moment";


const BlogDetails = () => {
    const [blogDetail, setBlogDetail] = useState([]);

    const param = useParams();
    // console.log("slug",param);

    const blogData = async(data) => {
        try {
            const response = await API.getBlogDetails(param.slug)
            console.log("response", response);
            setBlogDetail(response.data.data);

            const apiDate = response.data.created_at;
            console.log(apiDate);
            

        } catch (error) {
            
        }
    }
    
    useEffect(() => {
        blogData();
    }, []);
   

    return (
        <>
        <section class="movie_list">
            <div class="container-fluid">
                <div class="row justify-content-center">
                    <div class="col-sm-11">
                        <div class="row justify-content-center">
                            <div class="col-md-10">
                                <div class="blogDetails">
                                    <img src={IMG+ blogDetail.thumbnail} alt="blog-pic" class="img-fluid mb-4"/>
                                    <h3>{blogDetail.title}</h3>
                                    <ul class="d-flex mt-2 mb-2 info">
                                        <li><a href="#"><i class="fa-solid fa-user"></i> user</a></li>
                                        <li><a href="#"><i class="fa-solid fa-calendar-days"></i>{moment(blogDetail.created_at).format("DD MMMM, YYYY")}</a></li>
                                    </ul>
                                    <div dangerouslySetInnerHTML={{ __html: blogDetail.desc }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section> 
        </>
    );
}

export default BlogDetails;
